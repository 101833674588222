.pagination {
  justify-content: center;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-item .page-link {
  position: relative;
  display: block;
  margin: 0 10px;
  color: #007bff;
  text-decoration: none;
  font-size: small;
}

.page-item.active .page-link {
  font-weight: bold;
  font-size: small;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
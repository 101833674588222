* {
  margin: 0;
  padding: 0;
}

.container {
  overflow: hidden;
}

.center {
  margin:auto;
}


.news_table {
  padding-left: 3px;
  padding-right: 3px;
  font-size: 14px;
}

.sort_by_btn {
  font-size: 11px;
  padding-right: 10px;
  color: lightslategrey;
}

.tag_btn {
  padding: 0;
}

.nav_btns {
  font-size: 14px;
}

.no_padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

a.join_wait {
  color: white;
  font-size: 12px;
}
a.join_wait:hover {
  color: white;
  font-size: 12px;
}

.essay, .essay a, .essay a:hover{
  font-size: 14px;
  color: #222222;
}

.essay div {
  margin-top: 12px;
}

.add_link {
  font-size: 14px;
}

.sort_by_btn a {
  color: #4d4d4d;
}

.name {
  word-wrap: break-word;
}

.project {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 3px;
  padding-right: 3px;
  border-bottom: 1px solid #888;
}

.mrr_col {
  text-align: right;
  color: black;
}

.gray {
  color: #888;
}

.logout {
  text-align: right;
  color: #888;
  float: right;
  font-size: 0.8em;
}

a.vote_icon {
  cursor: pointer;
  text-decoration: none;
}

.vote_icon_idle {
  opacity: 0.3;
  font-size: .9em;
}

.vote_icon_idle_guest {
  opacity: 0.3;
  font-size: .9em;
}

.vote_icon_upvoted {
  opacity: 1;
  font-size: 1em;
}

.project_id {
  display: none;
}

.table_header {
  font-weight: bold;
}

.survey {
  font-size: .7em;
}

.project_name a {
  font-weight: bold;
  text-decoration: none;
}

.bookmark_btn_guest {
  padding-top: 12px;
  color: #bbb;
  font-size: 9px;
}

.bookmark_btn_guest > a {
  color: #bbb;
}

.bookmark_btn {
  padding-top: 12px;
  color: #bbb;
  font-size: 9px;
}

.bookmark_btn > a {
  color: #bbb;
}